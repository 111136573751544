import * as React from "react";
import styled from "styled-components";

const ImageWrapper = styled.span`
  display: inline-block;
  font-size: 0;

  img {
    width: 100%;
  }
`;

const ImageTag = ({ ...props }) => {
  if (props.src) {
    return (
      <ImageWrapper>
        <img src={props.src} alt={props.alt || ""} />
      </ImageWrapper>
    );
  } else {
    return null;
  }
};

export default ImageTag;
