import * as React from "react";
import isAbsoluteUrl from "is-absolute-url";
import { Link } from "gatsby";

const AnchorTag = ({ children: link, ...props }) => {
  if (link) {
    return isAbsoluteUrl(props.href) ? (
      <a href={props.href} rel="noopener" target="_blank">
        {link}
      </a>
    ) : (
      <Link to={props.href}>{link}</Link>
    );
  } else {
    return null;
  }
};

export default AnchorTag;
