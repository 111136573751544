import * as React from "react";
import styled from "styled-components";

const InfoWrapper = styled.div`
    border-radius: 0.2rem;
    box-shadow: 0rem 0.2rem 0.2rem #999;
    background: rgb(222, 235, 255);
    margin: 0 auto 5rem;
    padding: 2rem 2rem 2rem 6rem;
    position: relative;

    p {
        font-size: 1.6rem;
        line-height: 1.5;
    }

    pre {
        margin: 0;
        background: none;
        display: inline-block;
        white-space: pre-wrap;
    }

    span {
        display: block;
        background: blue;
        color: white;
        height: 3rem;
        width: 3rem;
        text-align: center;
        font-size: 2rem;
        line-height: 3rem;
        border-radius: 50%;
        left: 1.5rem;
        top: 2rem;
        position: absolute;
    }
`;

const InfoBox = ({ ...props }) => {
    if (props.children) {
        return (
            <InfoWrapper>
                <div>
                    <span aria-hidden="true">i</span>
                    <p>
                        {props.children}
                    </p>
                </div>
            </InfoWrapper>
        );
    } else {
        return null;
    }
};

export default InfoBox;